import { useState, useCallback, useEffect } from 'react';

function useClipboard(initialValue, optionsOrTimeout = {}) {
  const [hasCopied, setHasCopied] = useState(false);

  const [value, setValue] = useState(initialValue);

  const { timeout = 1500 } = typeof optionsOrTimeout === 'number' ? { timeout: optionsOrTimeout } : optionsOrTimeout;

  const onCopy = useCallback(() => {
    const didCopy = navigator.clipboard
      .writeText(value)
      .then(() => true)
      .catch(() => false);

    setHasCopied(didCopy);
  }, [value]);

  useEffect(() => {
    let timeoutId = null;

    if (hasCopied) {
      timeoutId = window.setTimeout(() => {
        setHasCopied(false);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [timeout, hasCopied]);

  return { value, setValue, onCopy, hasCopied };
}

export default useClipboard;
