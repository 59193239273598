import React from 'react';
import config from 'config';
import { checkAdmin } from 'utils/permissions';

const Context = React.createContext();

let user;
const tokenString = sessionStorage.getItem(config.TOKEN);
const userObj = JSON.parse(tokenString);
if (userObj) {
  const { token, ...rest } = userObj;
  user = rest;
} else {
  user = null;
}

const AuthProvider = (props) => {
  const [userDetails, setUserDetails] = React.useState(user);
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    setIsAdmin(checkAdmin(userDetails?.permissions));
  }, [userDetails]);

  return <Context.Provider value={{ userDetails, setUserDetails, isAdmin }} {...props} />;
};

export function useAuth() {
  const context = React.useContext(Context);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}

export default AuthProvider;
