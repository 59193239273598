import React, { useEffect, useState } from 'react';
import client from 'utils/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import './terminal.css';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import usePagination from 'hooks/usePagination';

function Terminals() {
  let navigate = useNavigate();

  const [terminals, setTerminals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const {
    paginationData: { currentPage, pageSize },
    handlePageChange,
    handlePageSizeChange,
  } = usePagination();

  useEffect(() => {
    setLoading(true);

    client
      .get(`/terminals?page=${currentPage}&pageSize=${pageSize}`)
      .then((response) => {
        setTerminals(() => response.data.data);
        setTotalCount(response.data.pagination.totalCount);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const renderDetailsButton = (params) => {
    return (
      <button
        onClick={() => navigate('/edit-terminal', { state: { terminal: params.row } })}
        className='terminal-add-button'
        variant='contained'
        color='primary'
        size='small'
        style={{ marginLeft: 16, backgroundColor: 'darkblue' }}>
        View
      </button>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50, disableClickEventBubbling: true },
    { field: 'agent_name', headerName: 'AgentName', width: 100, disableClickEventBubbling: true },
    { field: 'terminal_id', headerName: 'Terminal ID', width: 130, disableClickEventBubbling: true },
    { field: 'serial_number', headerName: 'Serial Number', width: 150, disableClickEventBubbling: true },
    { field: 'location', headerName: 'Agent Location', width: 100, disableClickEventBubbling: true },
    { field: 'agent_phone', headerName: 'Phone Number', width: 120, disableClickEventBubbling: true },
    { field: 'merchant_id', headerName: 'Merchant ID', width: 120, disableClickEventBubbling: true },
    { field: 'active', headerName: 'Active', disableClickEventBubbling: true },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 250,
      disableClickEventBubbling: true,
      valueGetter: (params) => `${dayjs(params.row.created_at).format('MMMM DD, YYYY h:mma')}`,
    },
    { field: 'actions', headerName: 'Actions', width: 250, disableClickEventBubbling: true, renderCell: renderDetailsButton },
  ];

  return (
    <div className='terminal'>
      <div className='terminal-title-container'>
        <h1 className='terminal-title'>Terminals</h1>
        <Link to='/new-terminal'>
          <button className='terminal-add-button'>Create</button>
        </Link>
      </div>
      <div className='terminals-list' style={{ height: 700, width: '97%' }}>
        <DataGrid
          rows={terminals}
          columns={columns}
          pageSize={pageSize}
          rowCount={totalCount}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
          loading={loading}
          paginationMode='server'
        />
      </div>
    </div>
  );
}

export default Terminals;
