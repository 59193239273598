import React from 'react';
import config from './config';
import IdleTimer from 'react-idle-timer';
import './App.css';
import ErrorBoundary from 'components/errorBoundary';
import Topbar from './components/topbar/TopBar';
import Sidebar from './components/sidebar/SideBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Terminals from './pages/terminals/Terminals';
import Staff from './pages/staff/Staff';
import Transactions from './pages/transactions/Transactions';
import Merchants from './pages/merchants/Merchants';
import Home from './pages/home/Home';
import Login from './pages/auth/Login';
import useToken from './components/app/useToken';
import NewMerchant from './pages/merchants/new/NewMerchant';
import EditMerchant from 'pages/merchants/edit/EditMerchant';
import NewStaff from './pages/staff/new/NewStaff';
import NewTerminal from './pages/terminals/new/NewTerminal';
import EditTerminal from 'pages/terminals/edit/EditTerminal';
import { useAuth } from 'contexts/AuthContext';
import ChangePassword from 'pages/auth/ChangePassword';

function App() {
  const idleTimer = React.useRef(null);
  const { token, setToken, logout } = useToken();
  const { userDetails } = useAuth();

  if (!token) {
    return <Login setToken={setToken} />;
  }

  // shouldn't change admin password from client
  if (userDetails?.metadata?.password_reset_requested) {
    return <ChangePassword logout={logout} />;
  }

  return (
    <>
      {/* logout user after specified iactivity time in miliseconds */}
      <IdleTimer ref={idleTimer} element={document} onIdle={logout} timeout={config.TIME_OUT} />

      <Router>
        <ErrorBoundary>
          <div>
            <Topbar />
            <div className='container'>
              <Sidebar logout={logout} />
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/terminals' element={<Terminals />} />
                <Route path='/edit-terminal' element={<EditTerminal />} />
                <Route path='/staff' element={<Staff />} />
                <Route path='/transactions' element={<Transactions />} />
                <Route path='/merchants' element={<Merchants />} />
                <Route path='/new-merchant' element={<NewMerchant />} />
                <Route path='/edit-merchant' element={<EditMerchant />} />
                <Route path='/new-staff' element={<NewStaff />} />
                <Route path='/new-terminal' element={<NewTerminal />} />
              </Routes>
            </div>
          </div>
        </ErrorBoundary>
      </Router>
    </>
  );
}

export default App;
