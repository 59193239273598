import React from 'react';
import client from 'utils/client';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useToast } from 'components/Toast/useToast';
import './new-terminal.css';
import TerminalForm from '../shared/TerminalForm';

const initialValues = {
  merchantId: '',
  terminalId: '',
  terminalAgentName: '',
  terminalAgentPhone: '',
  terminalLocation: '',
  terminalSerialNumber: '',
  transactionLimit: 0,
  transactionFee: 0,
  feeCap: 0,
  businessSegment: '',
};

function createTerminal(payload) {
  return client.post('/terminals/create', payload);
}
export default function NewTerminal() {
  const { onOpen } = useToast();
  let navigate = useNavigate();

  function handleSubmit(values) {
    return createTerminal(values)
      .then(() => {
        onOpen({ type: 'success', message: 'Created Successfully' });
        navigate('/terminals');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }
  return (
    <div className='new-terminal'>
      <h1 className='add-terminal-title'>New Terminal</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className='add-terminal-form'>
            <TerminalForm />
            <div className='add-terminal-button-container'>
              <button type='submit' className='add-terminal-button' disabled={isSubmitting}>
                {isSubmitting ? 'Creating....' : 'Create'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
