import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import client from 'utils/client';
import { useToast } from 'components/Toast/useToast';
import './new-merchant.css';
import MerchantForm from '../shared/MerchantForm';

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  stateOfOrigin: '',
  accountNumber: '',
  address: '',
  city: '',
  lga: '',
  state: '',
  nearestBusStop: ' ',
  businessStartDate: '',
  businessSegment: '',
};

function createMerchant(payload) {
  return client.post('/merchants/create', payload);
}
export default function NewMerchant() {
  const { onOpen } = useToast();
  let navigate = useNavigate();

  function handleSubmit(values) {
    return createMerchant(values)
      .then(() => {
        onOpen({ message: 'Created Successfully' });

        navigate('/merchants');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  return (
    <div className='new-merchant'>
      <h1 className='add-merchant-title'>New Merchant</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className='add-merchant-form'>
            <MerchantForm />
            <div className='add-merchant-button-container'>
              <button type='submit' className='add-merchant-button' disabled={isSubmitting}>
                {isSubmitting ? 'Creating....' : 'Create'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
