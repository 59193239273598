import React from 'react';
import client from 'utils/client';
import './featured-info.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';

function FeaturedInfo() {
  let [dashboardCount, setDashboardCount] = useState({ merchants: 0, terminals: 0, transactions: 0 });
  let [error, setError] = React.useState(null);

  const fetchDashboardCount = async () => {
    const [merchants, terminals, transactions] = await Promise.all([
      client
        .get(`/merchants/count`)
        .then((response) => response.data)
        .catch((error) => setError(error)),
      client
        .get(`/terminals/count`)
        .then((response) => response.data)
        .catch((error) => setError(error)),
      client
        .get(`/transactions/count`)
        .then((response) => response.data)
        .catch((error) => setError(error)),
    ]);

    setDashboardCount({ terminals: terminals?.data, merchants: merchants?.data, transactions: transactions?.data });
  };

  useEffect(() => {
    fetchDashboardCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    setDashboardCount({ merchants: 0, terminals: 0, transactions: 0 });
  }

  return (
    <div className='featured'>
      <div className='featured-item'>
        <div className='featured-title'>Merchants</div>
        <div className='featured-card-container'>
          <span className='featured-count'>{dashboardCount.merchants}</span>
        </div>
        <span className='featured-sub'>
          <Link to='/merchants' className='text-link'>
            View Merchants
          </Link>
        </span>
      </div>
      <div className='featured-item'>
        <div className='featured-title'>Terminals</div>
        <div className='featured-card-container'>
          <span className='featured-count'>{dashboardCount.terminals}</span>
        </div>
        <span className='featured-sub'>
          <Link to='/terminals' className='text-link'>
            View Terminals
          </Link>
        </span>
      </div>
      <div className='featured-item'>
        <div className='featured-title'>Transactions</div>
        <div className='featured-card-container'>
          <span className='featured-count'>{dashboardCount.transactions}</span>
        </div>
        <span className='featured-sub'>
          <Link to='/transactions' className='text-link'>
            View Transactions
          </Link>
        </span>
      </div>
    </div>
  );
}

export default FeaturedInfo;
