import React, { useState } from 'react';
import { useToast } from 'components/Toast/useToast';
import client from 'utils/client';
import './login.css';
import { useAuth } from 'contexts/AuthContext';

function Login({ setToken }) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { onOpen } = useToast();
  const { setUserDetails } = useAuth();

  // eslint-disable-next-line no-unused-vars
  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    return client
      .post(`/authentication/login`, {
        email,
        password,
      })
      .then((response) => {
        const { token, ...rest } = response.data.data;
        setUserDetails({ ...rest });
        setToken(response.data.data);
      })
      .catch((error) => {
        onOpen({ type: 'error', message: error?.message });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className='container'>
      <div className='header'>
        <h3>GTI Microfinance</h3>
      </div>
      <div className='login-wrapper'>
        <form className='login-form' onSubmit={handleSubmit}>
          <div className='login-form-content'>
            <h3 className='login-form-title'>Sign In</h3>
            <div className='login-input'>
              <label>Email</label>
              <input type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
            </div>
            <div className='login-input'>
              <label>Password</label>
              <input type='password' placeholder='Password' onChange={(e) => setPassword(e.target.value)} required />
            </div>
            <button className='login-button' disabled={loading}>
              {loading ? 'Logging in ...' : 'Log in'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
