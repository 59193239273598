import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import './sidebar.css';
import Home from '@mui/icons-material/Home';
import Storefront from '@mui/icons-material/Storefront';
import PointOfSale from '@mui/icons-material/PointOfSale';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from 'contexts/AuthContext';

function SideBar({ logout }) {
  const { isAdmin } = useAuth();
  const { pathname } = useLocation();

  return (
    <div className='sidebar'>
      <div className='sidebar-wrapper'>
        <div className='sidebar-menu'>
          <ul className='sidebar-list'>
            <NavLink to='/' className='text-link'>
              <li className='sidebar-list-items '>
                <Home className='sidebar-icon' />
                Home
              </li>
            </NavLink>
            <Link to='/merchants' className={`text-link ${pathname.includes('merchant') ? 'active' : ''}`}>
              <li className='sidebar-list-items'>
                <Storefront className='sidebar-icon' />
                Merchants
              </li>
            </Link>
            <Link to='/terminals' className={`text-link ${pathname.includes('terminal') ? 'active' : ''}`}>
              <li className='sidebar-list-items'>
                <PointOfSale className='sidebar-icon' />
                Terminals
              </li>
            </Link>
            <Link to='/transactions' className={`text-link ${pathname.includes('transaction') ? 'active' : ''}`}>
              <li className='sidebar-list-items'>
                <ReceiptIcon className='sidebar-icon' />
                Transactions
              </li>
            </Link>
            {isAdmin ? (
              <Link to='/staff' className={`text-link ${pathname.includes('staff') ? 'active' : ''}`}>
                <li className='sidebar-list-items'>
                  <PeopleAltIcon className='sidebar-icon' />
                  Staff
                </li>
              </Link>
            ) : null}

            <button id='logout' onClick={logout}>
              <LogoutIcon className='sidebar-icon' />
              Logout
            </button>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
