import React from 'react';
import { Formik, Form } from 'formik';
import { isEmpty } from 'lodash';
import client from 'utils/client';

import { useAuth } from 'contexts/AuthContext';
import { useToast } from 'components/Toast/useToast';

import { PasswordInput } from 'components/FormElements/TextInput';
import './login.css';

const initialValues = { currentPassword: '', newPassword: '', confirmPassword: '' };
function validation(values) {
  const errors = {};

  if (values.currentPassword === values.newPassword) {
    errors.newPassword = 'Enter a different password';
  }
  if (values.newPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords must match';
  }
  return errors;
}

const ChangePassword = ({ logout }) => {
  const { userDetails } = useAuth();
  const { onOpen } = useToast();

  function handleSubmit(values) {
    return client
      .post(`/staff/reset/password`, {
        email: userDetails.email,
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      })
      .then(() => {
        onOpen({ type: 'success', message: 'Password changed Successfully' });
        logout();
      })
      .catch((error) => {
        onOpen({ type: 'error', message: error?.message });
      });
  }

  return (
    <div className='container'>
      <div className='login-wrapper'>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validation}>
          {({ isSubmitting, errors }) => (
            <Form className='login-form'>
              <div className='login-form-content'>
                <h3 className='login-form-title'>Change Password</h3>
                <PasswordInput name='currentPassword' label='Current Password' placeholder='current password' required />
                <PasswordInput name='newPassword' label='New Password' placeholder='New password' required />
                <PasswordInput name='confirmPassword' label='Confirm Password' placeholder='Confirm password' required />
                <button className='login-button' disabled={isSubmitting || !isEmpty(errors)} type="submit">
                  {isSubmitting ? 'Submitting ...' : 'Submit'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ChangePassword;
