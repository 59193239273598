/* eslint-disable no-console */
import axios from 'axios';
import config from 'config';

let client = axios.create({
  baseURL: config.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

client.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(sessionStorage.getItem('__ACCESS_TOKEN__'));
    config.headers.Authorization = userInfo ? `Bearer ${userInfo.token}` : '';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/** Axios interceptors to transform error message for clientFn */
client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error.response.data);
  }
);

export default client;
