import React from 'react';
import { useFormikContext } from 'formik';
import STATE_OPTIONS from 'shared/states.json';
import { BUSINESS_CATEGORY } from 'shared';
import TextInput from 'components/FormElements/TextInput';
import CustomSelect from 'components/FormElements/CustomSelect';
import './merchant-form.css';

function selectOption(key = '') {
  return STATE_OPTIONS.find((option) => option.name === key);
}

const MerchantForm = () => {
  const [selectedStateOfBisiness, setSelectedStateOfBisiness] = React.useState('');
  const { setFieldValue, values } = useFormikContext();

  const lgas = React.useMemo(() => {
    const selectedValidState = selectOption(selectedStateOfBisiness);
    if (selectedValidState) return selectedValidState.lgas;
    return [];
  }, [selectedStateOfBisiness]);

  return (
    <div className='form-wrapper'>
      <h4>Merchant Details</h4>
      <div className='personal-details'>
        <TextInput className='add-merchant-item' name='firstName' label='First Name' placeholder='Ekekzie' required />
        <TextInput className='add-merchant-item' name='lastName' label='Last Name' placeholder='David' required />
        <TextInput className='add-merchant-item' name='phoneNumber' label='Phone Number' placeholder='08120000000' required />
        <TextInput className='add-merchant-item' type='email' name='email' label='Email' placeholder='jane.doe@example.com' />
        <CustomSelect
          className='add-merchant-item'
          id='active'
          name='stateOfOrigin'
          label='State of Origin'
          options={STATE_OPTIONS}
          onChange={(option) => setFieldValue('stateOfOrigin', option.value)}
          defaultInputValue={values.stateOfOrigin}
          required
        />
      </div>

      <h4>Business Details</h4>
      <div className='business-details'>
        <TextInput className='add-merchant-item' name='accountNumber' label='Account Number' placeholder='1234567890' required />
        <TextInput className='add-merchant-item' name='address' label='Address' placeholder='8 Oduduwa Crescent' required />
        <TextInput className='add-merchant-item' name='city' label='City' placeholder='Ikeja' required />

        <CustomSelect
          className='add-merchant-item'
          id='active'
          name='state'
          label='State'
          options={STATE_OPTIONS}
          onChange={(option) => {
            setFieldValue('state', option.value);
            setSelectedStateOfBisiness(option.value);
          }}
          defaultInputValue={values.state}
          required
        />
        <CustomSelect
          className='add-merchant-item'
          id='active'
          name='lga'
          label='L.G.A'
          isDisabled={!values.state}
          options={lgas}
          onChange={(option) => setFieldValue('lga', option.value)}
          defaultInputValue={values.lga}
          required
        />
        <TextInput className='add-merchant-item' name='nearestBusStop' label='Nearest Bus Stop' placeholder='jbh' required />
        <TextInput className='add-merchant-item' name='businessStartDate' label='Business Start Date' placeholder='2022-09-09' type='date' required />

        <CustomSelect
          className='add-merchant-item'
          id='active'
          name='businessSegment'
          label='Business Category'
          options={BUSINESS_CATEGORY}
          onChange={(option) => setFieldValue('businessSegment', option.value)}
          defaultInputValue={values.businessSegment}
          required
        />
      </div>
    </div>
  );
};

export default MerchantForm;
