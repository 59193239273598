import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ToastProvider from './components/Toast/useToast';
import ModalProvider from './components/Modal/useModal';
import AuthProvider from 'contexts/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <ToastProvider>
        <AuthProvider>
          <App />
        </AuthProvider>
      </ToastProvider>
    </ModalProvider>
  </React.StrictMode>
);
