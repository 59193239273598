import { useState } from 'react';
import config from 'config';
import { useAuth } from 'contexts/AuthContext';

export default function useToken() {
  const { setUserDetails } = useAuth();
  const getToken = () => {
    const tokenString = sessionStorage.getItem(config.TOKEN);
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    sessionStorage.setItem(config.TOKEN, JSON.stringify(userToken));
    setToken(userToken.token);
  };

  function logout() {
    setUserDetails(null);
    setToken(null);
    sessionStorage.removeItem(config.TOKEN);
    window.location.href = '/';
  }

  return {
    setToken: saveToken,
    token,
    logout,
  };
}
