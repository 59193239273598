import React from 'react';
import config from 'config';
import './topbar.css';

function Topbar() {
  const user = JSON.parse(sessionStorage.getItem(config.TOKEN));

  return (
    <div className='topbar'>
      <div className='topbar-wrapper'>
        <div className='top-left'>
          <span className='logo'>GTI Microfinance</span>
        </div>
        <div className='top-right'>
          <p className='current-user'>{`${user?.first_name} ${user?.last_name}`}</p>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
