import React, { forwardRef } from 'react';
import Select from 'react-select';
import { useField } from 'formik';

const transformOption = (option) => {
  if (option) return { label: option?.name ?? option, value: option?.name ?? option };
  else return;
};

const CustomSelect = ({ className = '', label, options = [], defaultValue = [], ...props }, ref) => {
  const [field] = useField(props);
  //allow react select handle it's internal state
  const { value, ...rest } = field;

  return (
    <div className={className}>
      <label>{label}</label>
      <Select options={options.map(transformOption)} defaultValue={defaultValue.map(transformOption)} ref={ref} {...rest} {...props} />
    </div>
  );
};

export default forwardRef(CustomSelect);
