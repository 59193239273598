import React, { forwardRef } from 'react';
import { useField } from 'formik';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const TextInput = ({ className = '', label, type = 'text', ...props }, ref) => {
  const [field] = useField(props);

  return (
    <div className={className}>
      <label>{label}</label>
      <input type={type} ref={ref} {...field} {...props} />
    </div>
  );
};

export default forwardRef(TextInput);

export const PasswordInput = forwardRef(({ className = '', label, ...props }, ref) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className={className} style={{ marginBottom: '25px' }}>
      <label>{label}</label>
      <Input
        id='standard-adornment-password'
        type={showPassword ? 'text' : 'password'}
        style={{ width: '100%' }}
        ref={ref}
        {...field}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton aria-label='toggle password visibility' onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff sx={{ fontSize: 15 }} /> : <Visibility sx={{ fontSize: 15 }} />}
            </IconButton>
          </InputAdornment>
        }
      />
      {meta.touched && meta.error ? <p className='form-error'>{meta.error}</p> : null}
    </div>
  );
});
