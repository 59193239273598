import React, { useEffect, useState } from 'react';
import client from 'utils/client';
import dayjs from 'dayjs';
import './transaction.css';
import { useModal } from 'components/Modal/useModal';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from 'react-csv';
import { formatCurrency } from 'utils/helpers';
import ModalComponent from 'components/Modal/Modal';
import TransactionDetails from './TransactionDetails';
import usePagination from 'hooks/usePagination';

const today = dayjs(new Date()).format('MM-DD-YYYY_h-mm-ssa');
function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = React.useState(0);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const { onOpen, isOpen } = useModal();
  const [selectedTransaction, setSelectedTransaction] = React.useState(null);

  const {
    paginationData: { currentPage, pageSize },
    handlePageChange,
    handlePageSizeChange,
  } = usePagination();

  useEffect(() => {
    setLoading(true);
    client
      .get(`/transactions?page=${currentPage}&pageSize=${pageSize}`)
      .then((response) => {
        setTransactions(() => response.data.data);
        setTotalCount(response.data.pagination.totalCount);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const exportData = React.useMemo(() => {
    if (!transactions.length > 0) return [];

    const dataToExport = transactions.map((transaction) => {
      const { metadata, provider_response, ...rest } = transaction;
      const { MaskedPAN, Nuban, CustomerName, CardScheme, RetrievalReferenceNumber: RRN, Stan } = provider_response;

      return { ...rest, MaskedPAN, Nuban, CustomerName, CardScheme, RRN, Stan };
    });

    return dataToExport;
  }, [transactions]);

  function viewDetails(transaction) {
    setSelectedTransaction(transaction);
    onOpen();
  }
  const renderDetailsButton = (params) => {
    return (
      <button
        onClick={() => viewDetails(params.row)}
        className='terminal-add-button'
        variant='contained'
        color='primary'
        size='small'
        style={{ marginLeft: 16, backgroundColor: 'darkblue' }}>
        View
      </button>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'reference', headerName: 'Reference', width: 310 },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => `${formatCurrency(params.row?.amount)}`,
    },
    {
      field: 'Stan',
      headerName: 'Stan',
      valueGetter: (params) => params.row?.provider_response?.Stan,
    },
    {
      field: 'RetrievalReferenceNumber',
      headerName: 'RRN',
      valueGetter: (params) => params.row?.provider_response?.RetrievalReferenceNumber,
      width: 130,
    },
    {
      field: 'MaskedPan',
      headerName: 'MaskedPan',
      valueGetter: (params) => params.row?.provider_response?.MaskedPAN,
      width: 170,
    },
    {
      field: 'Card Type',
      headerName: 'Card Type',
      valueGetter: (params) => params.row?.provider_response?.CardScheme,
      width: 150,
    },
    {
      field: 'Customer Name',
      headerName: 'Customer Name',
      valueGetter: (params) => params.row?.provider_response?.CustomerName,
      width: 150,
    },
    { field: 'merchant_id', headerName: 'Merchant Id' },
    { field: 'terminal_id', headerName: 'Terminal Id' },
    { field: 'status', headerName: 'Status' },
    {
      field: 'created_at',
      headerName: 'Transaction Date',
      width: 250,
      valueGetter: (params) => `${dayjs(params.row.created_at).format('MMMM DD, YYYY h:mma')}`,
    },
    { field: 'provider', headerName: 'Provider', width: 130 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      disableClickEventBubbling: true,
      renderCell: renderDetailsButton,
    },
  ];

  return (
    <>
      {isOpen ? (
        <ModalComponent title='Transaction Details' styles={{ width: '700px' }} children={<TransactionDetails data={selectedTransaction} />} />
      ) : null}

      <div className='transaction'>
        <div className='transaction-title-container'>
          <h1 className='transaction-title'>Transactions</h1>
          <CSVLink data={exportData} filename={`transactions_${today}.csv`}>
            <button className='transaction-add-button'>Export</button>
          </CSVLink>
        </div>
        <div className='transactions-list' style={{ height: 700, width: '97%' }}>
          <DataGrid
            rows={transactions}
            columns={columns}
            pageSize={pageSize}
            rowCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            loading={loading}
            paginationMode='server'
          />
        </div>
      </div>
    </>
  );
}

export default Transactions;
