import React from 'react';

const usePagination = (defaultPaginationState = { currentPage: 1, pageSize: 10 }) => {
  const [paginationData, setpaginationData] = React.useState(defaultPaginationState);

  const handlePageChange = (page) => {
    setpaginationData((prev) => ({ ...prev, currentPage: page + 1 }));
  };

  const handlePageSizeChange = (pageSize) => {
    setpaginationData((prev) => ({ ...prev, currentPage: 1, pageSize }));
  };

  const value = {
    paginationData,
    handlePageChange,
    handlePageSizeChange,
  };

  return value;
};

export default usePagination;
