import React from 'react';
import { useFormikContext } from 'formik';
import TextInput from 'components/FormElements/TextInput';
import './terminal-form.css';
import { BUSINESS_CATEGORY } from 'shared';
import CustomSelect from 'components/FormElements/CustomSelect';

const TerminalForm = () => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div className='form-wrapper'>
      <h4>Terminal Details</h4>
      <div className='personal-details'>
        <TextInput className='add-terminal-item' name='merchantId' label='Merchant ID' placeholder='4' required />
        <TextInput className='add-terminal-item' name='terminalId' label='Terminal ID' placeholder='20702YGP' required />
        <TextInput className='add-terminal-item' name='terminalSerialNumber' label='Terminal Serial No' placeholder='N300W1F6490' required />
        <TextInput className='add-terminal-item' name='terminalAgentName' label='Terminal Agent Name' placeholder='Jane Doe' required />
        <TextInput className='add-terminal-item' name='terminalAgentPhone' label='Terminal Agent phone' placeholder='08100000000' required />
        <TextInput
          className='add-terminal-item'
          name='terminalLocation'
          label='Terminal Location'
          placeholder='23, Emily Akinola str, Yaba'
          required
        />
        <CustomSelect
          className='add-merchant-item'
          id='active'
          name='businessSegment'
          label='Business Category'
          options={BUSINESS_CATEGORY}
          onChange={(option) => setFieldValue('businessSegment', option.value)}
          defaultInputValue={values.businessSegment}
          required
        />
        <TextInput className='add-terminal-item' type='number' name='transactionLimit' label='Transaction Limit' placeholder='2000' required />
        <TextInput className='add-merchant-item' type='number' name='transactionFee' label='Transaction Fee' placeholder='300' required />
        <TextInput className='add-merchant-item' type='number' name='feeCap' label='Fee Cap' placeholder='4000' required />
      </div>
    </div>
  );
};

export default TerminalForm;
