import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useModal } from './useModal';
import modalStyles from './modal.module.css';

const defaultStyle = {
  margin: '0 auto',
  marginTop: '2%',
  maxWidth: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
  maxHeight: '85vh',
};

const ModalComponent = ({ title, styles = {}, children }) => {
  const { isOpen, onClose } = useModal();

  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Box sx={{ ...defaultStyle, ...styles }}>
        {title && (
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {title}
          </Typography>
        )}
        <div className={modalStyles.container}>{children}</div>
      </Box>
    </Modal>
  );
};

export default ModalComponent;
