import React, { useEffect, useState } from 'react';
import client from 'utils/client';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import './merchant.css';
import { Link } from 'react-router-dom';
import { useToast } from 'components/Toast/useToast';
import usePagination from 'hooks/usePagination';

function Merchants() {
  let navigate = useNavigate();
  const { onOpen } = useToast();
  const [totalCount, setTotalCount] = React.useState(0);

  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);

  const {
    paginationData: { currentPage, pageSize },
    handlePageChange,
    handlePageSizeChange,
  } = usePagination();

  useEffect(() => {
    setLoading(true);
    client
      .get(`/merchants?page=${currentPage}&pageSize=${pageSize}`)
      .then((response) => {
        setMerchants(() => response.data.data);
        setTotalCount(response.data.pagination.totalCount);
      })
      .catch((error) => onOpen({ type: 'error', message: error?.message || 'An error Occured' }))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const renderDetailsButton = (params) => {
    return (
      <button
        onClick={() => navigate('/edit-merchant', { state: { merchant: params.row } })}
        className='terminal-add-button'
        variant='contained'
        color='primary'
        size='small'
        style={{ marginLeft: 16, backgroundColor: 'darkblue' }}>
        View
      </button>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'first_name', headerName: 'FirstName', width: 100 },
    { field: 'last_name', headerName: 'LastName', width: 100 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'phone_number', headerName: 'Phone Number', width: 120 },
    { field: 'account_number', headerName: 'Account Number', width: 120 },
    { field: 'active', headerName: 'Active' },
    { field: 'state', headerName: 'State', width: 130 },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 250,
      valueGetter: (params) => `${dayjs(params.row.created_at).format('MMMM DD, YYYY h:mma')}`,
    },
    { field: 'actions', headerName: 'Actions', width: 250, disableClickEventBubbling: true, renderCell: renderDetailsButton },
  ];

  return (
    <>
      <div className='merchant'>
        <div className='merchant-title-container'>
          <h1 className='merchant-title'>Merchants</h1>
          <Link to='/new-merchant'>
            <button className='merchant-add-button'>Create</button>
          </Link>
        </div>
        <div className='merchants-list' style={{ height: 700, width: '97%' }}>
          <DataGrid
            pagination
            rows={merchants}
            columns={columns}
            pageSize={pageSize}
            rowCount={totalCount}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[5, 10, 20, 30, 50, 100]}
            loading={loading}
            paginationMode='server'
          />
        </div>
      </div>
    </>
  );
}

export default Merchants;
