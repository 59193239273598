import React from 'react';
import client from 'utils/client';
import { Formik, Form } from 'formik';
import CustomSelect from 'components/FormElements/CustomSelect';
import { useToast } from 'components/Toast/useToast';
import { useAuth } from 'contexts/AuthContext';

import { STAFF_PERMISSIONS } from 'shared';

import styles from './edit-staff.module.css';
import { formatMutiselectValue } from 'utils/helpers';
import useClipboard from 'hooks/useClipboard';

function updateStaffPermissions(id, payload) {
  return client.post(`/staff/${id}/permissions`, payload);
}

function requestPasswordReset(id, payload) {
  return client.post(`staff/reset/${id}/request`, payload);
}

const EditStaff = ({ data }) => {
  const { onOpen } = useToast();
  const [loading, setLoading] = React.useState(false);
  const { setValue, value, onCopy } = useClipboard(null);
  //currently loggedin user
  const { isAdmin } = useAuth();

  const initialValues = {
    permissions: data?.permissions ?? [],
  };

  function handleCopy() {
    onCopy();
    onOpen({ message: 'copied to clipboard' });
  }

  function handleSubmit(values) {
    const payload = {};
    payload.permissions = values.permissions.map(formatMutiselectValue);

    return updateStaffPermissions(data.id, payload)
      .then(() => {
        onOpen({ message: 'Updated Successfully' });
        window.location.reload();
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  function requestPassword() {
    setLoading(true);
    requestPasswordReset(data.id, {})
      .then((response) => {
        if (response.data.message === 'success') {
          onOpen({ message: 'Password successfully reset' });
          setValue(response?.data?.data);
        }
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form className={styles.update_staff_form}>
            <CustomSelect
              className='add-staff-item'
              id='active'
              name='permissions'
              isMulti
              label='Permissions'
              options={STAFF_PERMISSIONS}
              defaultValue={data.permissions}
              onChange={(value) => setFieldValue('permissions', value)}
              required
            />
            <div className={styles.edit_staff_button_container}>
              <button type='submit' className={styles.edit_staff_button} disabled={isSubmitting || loading}>
                {isSubmitting ? 'Updating....' : 'Update'}
              </button>
              {isAdmin ? (
                <button
                  type='button'
                  className='reset-staff-password'
                  disabled={isSubmitting || loading}
                  onClick={() => requestPassword(data.id)}>
                  {loading ? 'Resetting...' : 'Reset Password'}
                </button>
              ) : null}
            </div>
            {value && (
              <p className={styles.new_password}>
                New Password:{' '}
                <span>
                  <button onClick={handleCopy} type='button' className={styles.copy_button}>
                    {value}
                  </button>
                </span>
              </p>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditStaff;
