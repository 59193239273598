export const BUSINESS_CATEGORY = [
  'Store/Supermarket',
  'Restaurants',
  'Wholesale',
  'Hotel',
  'Fuel Stations',
  'Logistics',
  'Church/NGO',
  'Telecoms',
  'Others'
];

export const STAFF_PERMISSIONS = [
  'VIEW_MERCHANTS',
  'VIEW_TERMINALS',
  'VIEW_TRANSACTIONS',
  'UPDATE_MERCHANT',
  'CREATE_MERCHANT',
  'CREATE_TERMINAL',
  'CREATE_STAFF'
];

export const DEFAULT_STAFF_PERMISSIONS = STAFF_PERMISSIONS.filter((permission) =>
  permission.startsWith('VIEW')
);
