import React from 'react';
import styles from './styles.module.css';

function DataGroup({ title, value }) {
  return (
    <div className={styles.container}>
      <h3>{title}</h3>
      <p>{value}</p>
    </div>
  );
}

export default DataGroup;
