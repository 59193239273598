import React from 'react';
import Toast from './Toast';

const Context = React.createContext();
const ToastProvider = (props) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [type, setType] = React.useState('success');

  const values = {
    isVisible,
    onClose: () => setIsVisible(false),
    onOpen: (params) => {
      params?.type && setType(params.type);
      params?.message && setMessage(params.message);
      setIsVisible(true);
    },
    message,
    type,
    setType,
  };

  return <Context.Provider value={values} {...props} />;
};

// custom hook for accessing Toast context
export const useToast = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
};

export default function RenderToast({ children }) {
  return (
    <ToastProvider>
      <Toast />
      {children}
    </ToastProvider>
  );
}
