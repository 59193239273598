import React from 'react';
import client from 'utils/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useToast } from 'components/Toast/useToast';
import './edit-terminal.css';
import TerminalForm from '../shared/TerminalForm';

function editTerminal(id, payload) {
  return client.put(`/terminals/${id}/update`, payload);
}

export default function EditTerminal() {
  const { state } = useLocation();
  let navigate = useNavigate();
  const { onOpen } = useToast();

  React.useEffect(() => {
    if (!state || !state.terminal) navigate('/terminal');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    merchantId: state?.terminal?.merchant_id ?? '',
    terminalId: state?.terminal?.terminal_id ?? '',
    terminalAgentName: state?.terminal?.agent_name ?? '',
    terminalAgentPhone: state?.terminal?.agent_phone ?? '',
    terminalLocation: state?.terminal?.location ?? '',
    terminalSerialNumber: state?.terminal?.serial_number ?? '',
    transactionLimit: state?.terminal?.transaction_limit ?? 0,
    transactionFee: state?.terminal?.transaction_fee ?? 0,
    feeCap: state?.terminal?.fee_cap ?? 0,
    businessSegment: state?.terminal?.business_segment ?? '',
  };

  function handleSubmit(values) {
    return editTerminal(state.terminal.id, values)
      .then(() => {
        onOpen({ type: 'success', message: 'Updated Successfully' });
        navigate('/terminals');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  function toggleTerminal() {
    return editTerminal(state.terminal.id, { active: !state.terminal.active })
      .then(() => {
        onOpen({ type: 'success', message: 'Updated Successfully' });
        navigate('/terminals');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: 'An Error Occured' });
        console.error(err);
      });
  }

  return (
    <div className='new-terminal'>
      <h1 className='add-terminal-title'>Edit Terminal</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form className='add-terminal-form'>
            <TerminalForm />
            <div className='edit-terminal-button-container'>
              <button type='submit' className='add-terminal-button' disabled={isSubmitting}>
                Edit
              </button>
              <button
                type='button'
                className={`disable-terminal-button ${state.terminal.active ? 'inactive' : ''}`}
                disabled={isSubmitting}
                onClick={toggleTerminal}>
                {state.terminal.active ? 'Disable' : 'Enable'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
