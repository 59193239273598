import React from 'react';
import client from 'utils/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { useToast } from 'components/Toast/useToast';
import './edit-merchant.css';
import MerchantForm from '../shared/MerchantForm';

function editMerchant(id, payload) {
  return client.put(`/merchants/${id}/update`, payload);
}
export default function EditMerchant() {
  const { state } = useLocation();
  let navigate = useNavigate();
  const { onOpen } = useToast();

  React.useEffect(() => {
    if (!state || !state.merchant) navigate('/merchants');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    firstName: state?.merchant?.first_name ?? '',
    lastName: state?.merchant?.last_name ?? '',
    phoneNumber: state?.merchant.phone_number ?? '',
    email: state?.merchant?.email ?? '',
    stateOfOrigin: state?.merchant?.state_of_origin ?? '',
    accountNumber: state?.merchant?.account_number ?? '',
    address: state?.merchant?.address ?? '',
    city: state?.merchant?.city ?? '',
    lga: state?.merchant?.lga ?? '',
    state: state?.merchant?.state ?? '',
    nearestBusStop: state?.merchant?.nearest_bus_stop ?? '',
    businessStartDate: dayjs(state?.merchant?.business_start_date).format('YYYY-MM-DD') ?? null,
    businessSegment: state?.merchant?.business_segment ?? '',
    transactionFee: state?.merchant?.transaction_fee ?? '',
    transactionFeeCap: state?.merchant?.transaction_fee_Cap ?? '',
  };

  function handleSubmit(values) {
    return editMerchant(state.merchant.id, values)
      .then(() => {
        onOpen({ message: 'Updated Successfully' });
        navigate('/merchants');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  function toggleMerchant() {
    return editMerchant(state.merchant.id, { active: !state.merchant.active })
      .then(() => {
        onOpen({ message: 'Updated Successfully' });
        navigate('/merchants');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  return (
    <div className='new-merchant'>
      <h1 className='add-merchant-title'>Edit Merchant</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form className='add-merchant-form'>
            <MerchantForm />
            <div className='edit-merchant-button-container'>
              <button type='submit' className='add-merchant-button' disabled={isSubmitting}>
                Edit
              </button>
              <button
                type='button'
                className={`disable-merchant-button ${state.merchant.active ? 'inactive' : ''}`}
                disabled={isSubmitting}
                onClick={toggleMerchant}>
                {state.merchant.active ? 'Disable' : 'Enable'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
