import React from 'react';
import client from 'utils/client';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import FeaturedInfo from 'components/featured-info/FeaturedInfo';
import { useEffect } from 'react';
import { useState } from 'react';
import { parseTransactionStatus } from 'utils/helpers';
import './home.css';

function Home() {
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    client
      .get(`/transactions?page=${1}&pageSize=${20}`)
      .then((response) => setTransactions(() => response.data.data))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const columns = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'reference', headerName: 'Reference', width: 310 },
    { field: 'amount', headerName: 'Amount',  width: 200 },
    { field: 'merchant_id', headerName: 'Merchant Id' },
    { field: 'terminal_id', headerName: 'Terminal Id' },
    { field: 'status', headerName: 'Status', valueGetter: (params) => parseTransactionStatus(params.row.status) },

    {
      field: 'created_at',
      headerName: 'Transaction Date',
      width: 250,
      valueGetter: (params) => `${dayjs(params.row.created_at).format('MMMM DD, YYYY h:mma')}`,
    },
    { field: 'provider', headerName: 'Provider', width: 130 },
  ];

  return (
    <div className='home'>
      <FeaturedInfo />
      <div className='transactions-list' style={{ height: 700, width: '97%' }}>
        <div className='transactions-title'>Transactions</div>
        <DataGrid rows={transactions} columns={columns} loading={loading} rowsPerPageOptions={[20]} />
      </div>
    </div>
  );
}

export default Home;
