import React from 'react';
import client from 'utils/client';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'components/Toast/useToast';
import CustomSelect from 'components/FormElements/CustomSelect';
import TextInput from 'components/FormElements/TextInput';
import { useAuth } from 'contexts/AuthContext';

import { DEFAULT_STAFF_PERMISSIONS, STAFF_PERMISSIONS } from 'shared';
import { formatMutiselectValue } from 'utils/helpers';
import './new-staff.css';

function createStaff(payload) {
  return client.post('/staff/create', payload);
}

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  permissions: DEFAULT_STAFF_PERMISSIONS,
};

export default function NewStaff() {
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin) window.location = '/';
  }, [isAdmin]);

  const { onOpen } = useToast();
  let navigate = useNavigate();

  function handleSubmit(values) {
    const payload = {
      ...values,
      permissions: values.permissions.map(formatMutiselectValue),
    };

    return createStaff(payload)
      .then(() => {
        onOpen({ message: 'Created Successfully' });
        navigate('/staff');
      })
      .catch((err) => {
        onOpen({ type: 'error', message: err?.message || 'An Error Occured' });
      });
  }

  return (
    <div className='new-staff'>
      <h1 className='add-staff-title'>New Staff</h1>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ isSubmitting, setFieldValue }) => (
          <Form className='add-staff-form'>
            <div className='form-wrapper'>
              <h4>Staff Details</h4>
              <div className='personal-details'>
                <TextInput className='add-staff-item' name='firstName' label='First Name' placeholder='Ekekzie' required />
                <TextInput className='add-staff-item' name='lastName' label='Last Name' placeholder='David' required />
                <TextInput className='add-staff-item' name='email' type='email' label='Email' placeholder='jane.doe@example.com' required />
                <TextInput className='add-staff-item' name='password' type='password' label='Password' placeholder='Password' required />
                <CustomSelect
                  className='add-staff-item'
                  id='active'
                  name='permissions'
                  isMulti
                  label='Permissions'
                  options={STAFF_PERMISSIONS}
                  defaultValue={DEFAULT_STAFF_PERMISSIONS}
                  onChange={(value) => setFieldValue('permissions', value)}
                  required
                />
              </div>
            </div>
            <button type='submit' className='add-staff-button' disabled={isSubmitting}>
              {isSubmitting ? 'Creating....' : 'Create'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
