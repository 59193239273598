import React, { Component } from 'react';
import styles from './styles.module.css';

export const ErrorBoundaryFallback = () => (
  <div className={styles.container}>
    <div className={styles.message}>
      Hey, something went wrong!
      <button onClick={() => window.location.reload()} className={styles.refresh}>
        Please refresh!
      </button>
      <span role='img' aria-label='face-emoji'>
        {' '}
        😞
      </span>
    </div>
  </div>
);

class ErrorBoundary extends Component {
  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    this.setState({ error, info });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return <ErrorBoundaryFallback />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
