/* eslint-disable indent */
export function formatCurrency(amount, currency = 'NGN') {
  const amountDefined = Number(amount) || 0;
  return amountDefined
    ? amountDefined
        .toLocaleString('en-NG', {
          style: 'currency',
          currency,
        })
        .slice(0, -3)
    : amountDefined;
}

export const parseStatus = (status = '') => (status ? 'active' : 'inactive');
export const parseTransactionStatus = (status = '') => (Number(status) ? 'Successful' : 'Pending');

export function formatMutiselectValue(item) {
  if (item) {
    return item?.value ?? item;
  }
  return;
}
