import React from 'react';
import dayjs from 'dayjs';
import DataGroup from 'components/DataGroup';

const TransactionDetails = ({ data }) => {
  return (
    <div className='modal-body-grid'>
      <DataGroup title='Amount' value={`${data?.currency} ${data?.amount}`} />
      <DataGroup title='Reference' value={data?.reference} />
      <DataGroup title='Customer' value={data?.provider_response?.CustomerName} />
      <DataGroup title='Terminal ID' value={data?.terminal_id} />
      <DataGroup title='Merchant ID' value={data?.merchant_id} />
      <DataGroup title='Status' value={data?.ststus} />
      <DataGroup title='Date' value={dayjs(data?.transaction_date).format('MMMM DD, YYYY h:mma')} />
      <DataGroup title='Provider' value={data?.provider} />
      <DataGroup title='Type' value={data?.provider_response?.Type} />
      <DataGroup title='Stan' value={data?.provider_response?.Stan} />
      <DataGroup title='Nuban' value={data?.provider_response?.Nuban} />
      <DataGroup title='MaskedPAN' value={data?.provider_response?.MaskedPAN} />
      <DataGroup title='Transaction Reference' value={data?.provider_response?.TransactionReference} />
      <DataGroup title='Retrieval Reference Number' value={data?.provider_response?.RetrievalReferenceNumber} />
      <DataGroup title='Description' value={data?.provider_response?.StatusDescription} />
    </div>
  );
};

export default TransactionDetails;
