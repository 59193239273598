import React, { useEffect, useState } from 'react';
import client from 'utils/client';
import dayjs from 'dayjs';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import ModalComponent from 'components/Modal/Modal';
import { useModal } from 'components/Modal/useModal';
import { useToast } from 'components/Toast/useToast';
import { useAuth } from 'contexts/AuthContext';
import './staff.css';
import EditStaff from './edit/EditStaff';
import { parseStatus } from 'utils/helpers';

function toggleStaff(id, payload) {
  return client.put(`/staff/${id}/update`, payload);
}

function Staff() {
  const { isAdmin } = useAuth();

  React.useEffect(() => {
    if (!isAdmin) window.location = '/';
  }, [isAdmin]);

  const [loading, setLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [staff, setStaff] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);

  const { isOpen, onOpen } = useModal();
  const { onOpen: OpenToast } = useToast();
  const [selectedStaff, setSelectedStaff] = useState(null);

  useEffect(() => {
    setLoading(true);
    client
      .get(`/staff`)
      .then((response) => {
        setStaff(() => response.data.data);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  function handleToggleStaff(id, active) {
    setIsUpdating(true);
    return toggleStaff(id, { active: !active })
      .then(() => {
        OpenToast({ message: 'Updated Successfully' });
        window.location.reload();
      })
      .catch((err) => {
        OpenToast({ type: 'error', message: err?.message || 'An Error Occured' });
      })
      .finally(() => setIsUpdating(false));
  }

  function toggleStatusButton({ active, id }) {
    return (
      <button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleToggleStaff(id, active);
        }}
        className={`staff-add-button ${active ? 'disable' : 'enable'}`}
        variant='contained'
        color='primary'
        size='small'
        disabled={isUpdating}
        style={{ marginLeft: 16, backgroundColor: 'darkblue' }}>
        {active ? 'Disable' : 'Enable'}
      </button>
    );
  }

  const renderDetailsButton = (params) => {
    const { active, id } = params.row;
    return (
      <>
        <button
          onClick={() => {
            setSelectedStaff(params.row);
            onOpen();
          }}
          className='staff-add-button'
          variant='contained'
          color='primary'
          size='small'
          disabled={isUpdating}
          style={{ marginLeft: 16, backgroundColor: 'darkblue' }}>
          Edit
        </button>
        {toggleStatusButton({ active, id })}
      </>
    );
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 10 },
    { field: 'first_name', headerName: 'FirstName', width: 100 },
    { field: 'last_name', headerName: 'LastName', width: 100 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'active', headerName: 'Status', valueGetter: (params) => parseStatus(params.row.active) },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 250,
      valueGetter: (params) => `${dayjs(params.row.created_at).format('MMMM DD, YYYY h:mma')}`,
    },
    { field: 'actions', headerName: 'Actions', width: 250, disableClickEventBubbling: true, renderCell: renderDetailsButton },
  ];

  return (
    <>
      {isOpen ? (
        <ModalComponent title='Edit Staff' styles={{ maxWidth: '500px', minHeight: '400px' }} children={<EditStaff data={selectedStaff} />} />
      ) : null}

      <div className='staff'>
        <div className='staff-title-container'>
          <h1 className='staff-title'>Staff</h1>
          <Link to='/new-staff'>
            <button className='staff-add-button'>Create</button>
          </Link>
        </div>
        <div className='staff-list' style={{ height: 700, width: '97%' }}>
          <DataGrid rows={staff} columns={columns} pageSize={12} rowsPerPageOptions={[12, 25, 50, 100]} loading={loading} />
        </div>
      </div>
    </>
  );
}

export default Staff;
