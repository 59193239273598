import React from 'react';

const Context = React.createContext();
const ModalProvider = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const values = {
    isOpen,
    onClose: () => setIsOpen(false),
    onOpen: () => setIsOpen(true),
  };
  return <Context.Provider value={values} {...props} />;
};

export default ModalProvider;

// custom hook for accessing Modal context
export const useModal = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
};
